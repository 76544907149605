<template>
  <div>
    <StateCoor />
  </div>
</template>

<script>
import StateCoor from '@/infdb_components/StateCoor.vue'
export default {
  components: {
    StateCoor
  }
}
</script>