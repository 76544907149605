<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">State Coor</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.description="{ item }">
              <span>{{ item.description | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <!--<template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      color="primary"
                      dark
                      class="ma-1 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add A Request
                    </v-btn>
                  </template>-->
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="assigned_days"
                              label="Assigned Days*"
                              required
                              type="number"
                              :rules="daysRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon class="mr-2" @click="editItem(item)" >
                mdi-lock-reset
              </v-icon> 
              <!--<v-icon class="mr-2" @click="editPrimaryStatus(item)" >
                mdi-tree
              </v-icon>-->
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
      
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>
  
<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
  
export default {
  components: {
    Layout
  },
  data() {
    return {
      snackbar: false,
      snackbartext:'',
      formTitle:'Data Clerk Activation',
      valid:'',
      mode:'new',

      requested_days:'',
      allocated_farmers:'',

      assigned_days:'',
      request_id:'',
      clerk_id:'',

      dialog: false,
      dialog2: false,
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'This table has no record!',

      commodity_disabled:false,
      verifyBtnLoader: false,
      formDisabled: true,
      formBtnLoader: false,
      formDisabled2: true,
      formBtnLoader2: false,
      headers: [
        {
          text: 'Coordinator',
          align: 'start',
          sortable: false,
          value: 'Coordinator'
        },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Defualt Pwd', value: 'defualt_permission' },
        { text: 'State', value: 'state' },
        { text: 'Status', value: 'status' },
        { text: 'Reset Pwd', value: 'actions', sortable: false }
      ],
      tableData: [],
      editedIndex: -1,
      nameRules: [
        (v) => !!v || 'Location is required',
        (v) => (v && v.length >= 2) || 'Location must be greater than 2 characters'
      ],
      clusterRules: [
        (v) => !!v || 'Cluster is required',
        (v) => (v && v.length >= 2) || 'Cluster must be greater than 2 characters'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
      ],
      daysRules: [
        (v) => !!v || 'Requested Days is required',
        (v) => (!isNaN(parseFloat(v))) || 'Requested Days must be a number',
        (v) => (v > 0) || 'Requested Days must be greater than 0',
        (v) => (v < 15) || 'Requested Days must be less than 15'
      ],
      farmersRules: [
        (v) => !!v || 'Allocated farmers is required',
        (v) => (!isNaN(parseFloat(v))) || 'Allocated farmer must be a number',
        (v) => (v && (v > 0 )) || 'Allocated farmer must be greater than 1',
        (v) => (v < 501) || 'Requested Days must be less than 501'
      ]
    }
  },
  created() {

    this.userData = httpServ.getSessionJson('userData')
    //this.getFormOption()
    this.getTableData()

    //console.log(this.userData)
  },
  mounted() {},
  methods: {
    isEditOk: function(item) {
      if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }
    },
    getTableData: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/admin/statesCoor')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { })
      
      resValue.then((result) => {
        if (result.message === 'Request Ok!') {
          ///console.log(result.data)
          this.tableData = result.data
          this.record_count = 1
        }
        if (result.message === 'No record!') {
          this.record_count = 0
        }
        //console.log(this.tableData)
      })
      this.tableLoading = false
    },
    getNewData: function () {
      const data = {
        status: 'new',
        stakeholder_id:this.stakeholder_id.id,
        requested_days:this.requested_days,
        allocated_farmers:this.allocated_farmers,
        comments:this.comments,
        clerk_id:this.clerk_id.id,
        admin_id:this.userData.admin_id
      }

      console.log(data)

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        request_id: this.request_id,
        clerk_id:this.clerk_id,
        assigned_days:this.assigned_days
      }

      return data
    },
    postData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()
      
      //validate
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        //const formUrl1 = httpServ.getNAGSAPUrl('/enumeration/stateCoor/dataEntryRequest/new')
        const formUrl2 = httpServ.getNAGSAPUrl('/enumeration/admin/activateDataClerk')
        const url = this.mode === 'new' ? formUrl1 : formUrl2
        const resp = httpServ.postFetch(url,postData)

        resp.then((result) => {
          if (result.status === 200) {
            this.formBtnLoader = false
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            this.formBtnLoader = false
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            //this.formBtnLoader = false
          } else {
            this.formBtnLoader = false
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      this.request_id = item.request_id
      this.clerk_id = item.clerk_id
      this.assigned_days =  item.requested_days
      //this.dialog = true
      this.mode = 'update'
      this.formTitle = 'Data Clerk Activation'
    },   
    editPrimaryStatus(item) {
      //console.log(item)
      this.selected_commodity = item.commodity
      this.selected_commodity_rf = item.commodity_rf
      this.dialog2 = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
      //this.editedItem = Object.assign({}, this.defaultItem)
        this.first_name = '',
        this.surname = '',
        this.phone = '',
        this.email = '',
        this.mode = 'new' 
        this.formTitle = 'New Data Clerk Addition'
      })
    },
    close2() {
      this.selected_commodity = ''
      this.selected_commodity_rf = ''
      this.dialog2 = false
    }
  }
}
</script>
  
  <style scoped>
  </style>
