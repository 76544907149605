<template>
  <div>
    <DCRequestActivation />
  </div>
</template>

<script>
import DCRequestActivation from '@/infdb_components/DCRequestActivation.vue'
export default {
  components: {
    DCRequestActivation
  }
}
</script>