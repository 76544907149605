<template>
  <div>
    <StateQuota />
  </div>
</template>

<script>
import StateQuota from '@/infdb_components/StateQuota.vue'
export default {
  components: {
    StateQuota
  }
}
</script>