export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-account-network', key: 'menu.blank', text: 'State Quota', link: '/stateQuota' },
    //{ icon: 'mdi-account-edit', key: 'menu.blank', text: 'Data Clerk', link: '/dataClerk' },
    //{ icon: 'mdi-account-convert', key: 'menu.blank', text: 'Data Clerk Activation', link: '/dCRequestActivation' },
    //{ icon: 'mdi-group', key: 'menu.blank', text: 'Irrigation Cluster', link: '/cluster' },
    //{ icon: 'mdi-account-group', key: 'menu.blank', text: 'Commodity Association', link: '/summary' },
    //{ icon: 'mdi-chart-bell-curve', key: 'menu.blank', text: 'Summaries', link: '/summary' },
    //{ icon: 'mdi-bell', key: 'menu.blank', text: 'Picture', link: '/picture' } ,
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/' } 
  ]
}]
