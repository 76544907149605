<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Data Clerk</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            group-by="state"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.description="{ item }">
              <span>{{ item.description | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon class="mr-2" @click="editItem(item)" >
                mdi-file-document-edit
              </v-icon> 
              <!--<v-icon class="mr-2" @click="editPrimaryStatus(item)" >
                mdi-tree
              </v-icon>-->
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
      
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>
  
<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
  
export default {
  components: {
    Layout
  },
  data() {
    return {
      snackbar: false,
      snackbartext:'',
      formTitle:'New Data Clerk Addition',
      valid:'',
      mode:'new',

      first_name:'',
      surname:'',
      phone:'',
      email:'',
      clerk_id:'',

      dialog: false,
      dialog2: false,
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'This table has no record!',

      commodity_disabled:false,
      verifyBtnLoader: false,
      formDisabled: true,
      formBtnLoader: false,
      formDisabled2: true,
      formBtnLoader2: false,

      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'clerk_id'
        },
        { text: 'Clerk', value: 'names' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Default Pwd', value: 'defualt_permission' }
        //{ text: 'Modify', value: 'actions', sortable: false }
      ],
      tableData: [],
      editedIndex: -1,
      nameRules: [
        (v) => !!v || 'Location is required',
        (v) => (v && v.length >= 2) || 'Location must be greater than 2 characters'
      ],
      clusterRules: [
        (v) => !!v || 'Cluster is required',
        (v) => (v && v.length >= 2) || 'Cluster must be greater than 2 characters'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
      ],
      numberRules: [
        (v) => !!v || 'Farm size is required',
        (v) => (!isNaN(parseFloat(v))) || 'Farm size must be a number',
        (v) => (v && v > 0 ) || 'Famr size must greater than zero'
      ]
    }
  },
  created() {

    this.userData = httpServ.getSessionJson('userData')
    //this.getFormOption()
    this.getTableData()

    //console.log(this.userData)
  },
  mounted() {},
  methods: {
    isEditOk: function(item) {
      if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }
    },
    getFormOption: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/formOptions/stateCoor/stakeholder')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {})

      resValue.then((result) => {
        if (result.status === 200) {

          this.stakeholder_type_arr = result.data.stakeholder
          //this.farmer_id = result.data.
        }
      })
    }, 
    getTableData: function() {
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/admin/dataClerk')
      const resValue = httpServ.makeRequest(formUrl, 'GET', { 
        'admin_id':this.userData.admin_id
      })
      
      resValue.then((result) => {
        if (result.message === 'Request Ok!') {
          //console.log(result.data)
          this.tableData = result.data
          this.record_count = 1
        }
        if (result.message === 'No record!') {
          this.record_count = 0
        }
        //console.log(this.tableData)
      })
      this.tableLoading = false
    },
    getNewData: function () {
      const data = {
        status: 'new',
        first_name: this.first_name,
        surname: this.surname,
        phone: this.phone,
        email: this.email,
        state:this.userData.work_state,
        admin_id:this.userData.admin_id,
        state_rf:this.userData.state_rf
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        first_name: this.first_name,
        surname: this.surname,
        phone: this.phone,
        email: this.email,
        clerk_id:this.clerk_id,
        state:this.userData.work_state,
        admin_id:this.userData.admin_id,
        state_rf:this.userData.state_rf
      }

      return data
    },
    postData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()
      
      //validate
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        const formUrl1 = httpServ.getAuthenUrl('/nagsap/stateCoor/createDataClerk')
        const formUrl2 = httpServ.getAuthenUrl('/nagsap/stateCoor/updateDataClerk')
        const url = this.mode === 'new' ? formUrl1 : formUrl2
        const resp = httpServ.postFetch(url,postData)

        resp.then((result) => {
          if (result.status === 200) {
            this.formBtnLoader = false
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            this.formBtnLoader = false
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            //this.formBtnLoader = false
          } else {
            this.formBtnLoader = false
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    postData2: function () {
      const postData = {
        commodity_rf: this.selected_commodity_rf,
        phone: this.farmerData.phone
      }
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/stakeholder/tableData')
      const resp = httpServ.postFetch(formUrl,postData)

      resp.then((result) => {
        if (result.status === 200) {
          this.formBtnLoader = false
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close2()
          this.getTableData()
          
        } else if (result.status === 422) {
            
          if (result instanceof SyntaxError) {
            // Unexpected token < in JSON
            //console.log('There was a SyntaxError', result)//checker
            this.snackbartext = result.message
            this.snackbar = true
          } else {
            //console.log('There was an error', result) //Checker
            this.snackbartext = result.message
            this.snackbar = true
          }
          this.formBtnLoader = false
        } else {
          this.snackbartext = result.message 
          this.snackbar = true
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      this.first_name = item.firstname
      this.surname = item.surname
      this.phone =  item.phone
      this.email =  item.email
      this.clerk_id = item.clerk_id

      this.dialog = true
      this.mode = 'update'
      this.formTitle = 'Data Clerk Update'
    },   
    editPrimaryStatus(item) {
      //console.log(item)
      this.selected_commodity = item.commodity
      this.selected_commodity_rf = item.commodity_rf
      this.dialog2 = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
      //this.editedItem = Object.assign({}, this.defaultItem)
        this.first_name = '',
        this.surname = '',
        this.phone = '',
        this.email = '',
        this.mode = 'new' 
        this.formTitle = 'New Data Clerk Addition'
      })
    },
    close2() {
      this.selected_commodity = ''
      this.selected_commodity_rf = ''
      this.dialog2 = false
    }
  }
}
</script>
  
  <style scoped>
  </style>
