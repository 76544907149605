<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">State Quota</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            ref="myTable"
            :headers="headers"
            :items="tableData"
            sort-by="id"
            :search="search"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
            @current-items="filteredList"
          >
            <template v-slot:item.commodity="{ item }">
              <span>{{ item.commodity | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      color="primary"
                      dark
                      class="ma-1 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add Quota
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="state_rf"
                              return-object
                              :items="state_arr"
                              item-text="title"
                              item-value="id"
                              label="State*"
                              :rules="[v => !!v || 'State is required']"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="lga"
                              label="lga*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            class="py-0"
                          >
                            <v-select
                              v-model="intervention_rf"
                              return-object
                              :items="intervention_arr"
                              item-text="title"
                              item-value="id"
                              label="Intervention*"
                              :rules="[v => !!v || 'Intervention is required']"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            class="py-0"
                          >
                            <v-select
                              v-model="season_rf"
                              return-object
                              :items="season_arr"
                              item-text="title"
                              item-value="id"
                              label="Season*"
                              :rules="[v => !!v || 'Season is required']"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="cropping_year"
                              label="Year*"
                              required
                              :rules="yearRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="cluster_irrigation_site"
                              label="Cluster Irrigation Site*"
                              required
                              :rules="clusterRules"
                              hint="Seperated by comma"
                              persistent-hint
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="input_collection_centers"
                              label="Input Collection Centers*"
                              required
                              :rules="clusterRules"
                              hint="Seperated by comma"
                              persistent-hint
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="rice_allocated_hectarage"
                              label="Rice Allocated (HA)*"
                              required
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="rice_assigned_numbers_of_farmers"
                              label="Rice Assigned # of farmers*"
                              required
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="maize_allocated_hectarage"
                              label="Maize Allocated (HA)*"
                              required
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="maize_assigned_numbers_of_farmers"
                              label="Maize Assigned # of farmers*"
                              required
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="cassava_allocated_hectarage"
                              label="Cassava Allocated (HA)*"
                              required
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="cassava_assigned_numbers_of_farmers"
                              label="Cassava Assigned # of farmers*"
                              required
                              :rules="numberRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2" max-width="600px">
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        Change Quota Status
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close2">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData2()"
                      >
                        Continue
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="ml-6"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon class="mr-2" @click="editItem(item)" >
                mdi-file-document-edit
              </v-icon>  
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
            <template v-slot:body.append="{headers}">
              <tr>
                <td v-for="(header,i) in headers" :key="i">
                  <div v-if="header.value == 'rice_allocated_hectarage'" class="font-weight-bold">
                    {{ sumField('rice_allocated_hectarage') }} 
                  </div>
                  <div v-if="header.value == 'rice_assigned_numbers_of_farmers'" class="font-weight-bold">
                    {{ sumField('rice_assigned_numbers_of_farmers') }}
                  </div>
                  <div v-if="header.value == 'maize_allocated_hectarage'" class="font-weight-bold">
                    {{ sumField('maize_allocated_hectarage') }} 
                  </div>
                  <div v-if="header.value == 'maize_assigned_numbers_of_farmers'" class="font-weight-bold">
                    {{ sumField('maize_assigned_numbers_of_farmers') }}
                  </div>
                  <div v-if="header.value == 'cassava_allocated_hectarage'" class="font-weight-bold">
                    {{ sumField('cassava_allocated_hectarage') }} 
                  </div>
                  <div v-if="header.value == 'cassava_assigned_numbers_of_farmers'" class="font-weight-bold">
                    {{ sumField('cassava_assigned_numbers_of_farmers') }}
                  </div>
                  <div v-else>
                    <!-- empty table cells for columns that don't need a sum -->
                  </div>

                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
      
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>
  
<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
  
export default {
  components: {
    Layout
  },
  data() {
    return {
      snackbar: false,
      snackbartext:'',
      formTitle:'New Quota Addition',
      valid:'',
      mode:'new',

      dialog: false,
      dialog2: false,
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'This table has no record!',

      state_rf:'',
      state_arr:{},
      cropping_year:'',
      cropping_season:'',
      lga:'',
      cluster_irrigation_site:'',
      input_collection_centers:'',
      intervention_arr:[],
      intervention_rf:'',
      season_rf:'',
      rice_allocated_hectarage:'',
      rice_assigned_numbers_of_farmers: '',
      maize_allocated_hectarage: '',
      maize_assigned_numbers_of_farmers: '',
      cassava_allocated_hectarage: '',
      cassava_assigned_numbers_of_farmers:'',
      season_arr: [{
        id:'Dry',
        title:'Dry' },
      {
        id:'Wet',
        title:'Wet' }],

      verifyBtnLoader: false,
      formDisabled: true,
      formBtnLoader: false,
      formDisabled2: true,
      formBtnLoader2: false,

      headers: [
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state'
        },
        { text: 'LGA', value: 'lga' ,filterable: false },
        //{ text: 'Season', value: 'cropping_season' ,filterable: false },
        //{ text: 'Year', value: 'cropping_year' ,filterable: false },
        { text: 'Period', value: 'year_season' ,filterable: false },
        { text: 'Intervention', value: 'intervention' ,filterable: false },
        { text: 'Irrigation Site', value: 'cluster_irrigation_site' ,filterable: false },
        { text: 'Collection Centers', value: 'input_collection_centers',filterable: false  },  
        
        { text: 'Rice (HA)', value: 'rice_allocated_hectarage' ,filterable: false },
        { text: 'Rice (Farmers)', value: 'rice_assigned_numbers_of_farmers' ,filterable: false },
        { text: 'Maize (HA)', value: 'maize_allocated_hectarage' ,filterable: false },
        { text: 'Maize (Farmers)', value: 'maize_assigned_numbers_of_farmers' ,filterable: false },
        { text: 'Cassava (HA)', value: 'cassava_allocated_hectarage' ,filterable: false },
        { text: 'Cassava (Farmers)', value: 'cassava_assigned_numbers_of_farmers' ,filterable: false }
        
        //{ text: 'Modify', value: 'actions', sortable: false }
      ],
      tableData: [],
      tableDataFiltered:[],
      search:'',
      editedIndex: -1,
      nameRules: [
        (v) => !!v || 'Field is required',
        (v) => (v && v.length >= 2) || 'Field must be greater than 2 characters'
      ],
      clusterRules: [
        (v) => !!v || 'Cluster is required',
        (v) => (v && v.length >= 2) || 'Cluster must be greater than 2 characters'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
      ],
      yearRules: [
        (v) => !!v || 'Year is required',
        (v) => (!isNaN(parseFloat(v))) || 'Year  must be a number',
        (v) => (v && v > 2022 ) || 'Year must greater than 2022'
      ],
      numberRules: [
        (v) => !!v || 'Allocation is required',
        (v) => (!isNaN(parseFloat(v))) || 'Allocation must be a number',
        (v) => (v && v > -1 ) || 'Allocation must a number zero or greater'
      ]
    }
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    this.getTableData()
    this.getFormOption()
    /*if (this.farmerData.farmer_id === '') {
      this.getFormOptionWithPhone()
    } else {
      this.getFormOptionWithFarmerId()
      this.getTableData()
    }*/
  },
  mounted() {},
  methods: {
    filteredList() {
      this.tableDataFiltered = this.$refs.myTable.$children[0].filteredItems
    },
    sumField(key) {
      return this.tableDataFiltered.reduce((a, b) => a + (b[key] || 0), 0)
    },
    isEditOk: function(item) {
      if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }
    },
    getFormOption: function() {
      const formUrl = httpServ.getNAGSAPUrl('/admin/formOptions/saveStateQuota')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {})

      resValue.then((result) => {
        if (result.status === 200) {
          this.state_arr = result.data.states
          this.intervention_arr = result.data.interventions
        }
      })
    }, 
    getTableData: function() {
      const formUrl = httpServ.getNAGSAPUrl('/admin/stateQuotaTable')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {})
      
      resValue.then((result) => {
        if (result.message === 'Request Ok!') {
          this.tableData = result.data
          this.record_count = 1
        }
        if (result.message === 'No record!') {
          this.record_count = 0
        }
        //console.log(this.tableData)
      })
      this.tableLoading = false
    },
    getNewData: function () {
      const data = {
        status: 'new',
        state:this.state_rf.id,
        cropping_year:this.cropping_year,
        cropping_season:this.season_rf.id,
        lga:this.lga,
        intervention:this.intervention_rf.id,
        cluster_irrigation_site:this.cluster_irrigation_site,
        input_collection_centers:this.input_collection_centers,
        rice_allocated_hectarage:this.rice_allocated_hectarage,
        rice_assigned_numbers_of_farmers: this.rice_assigned_numbers_of_farmers,
        maize_allocated_hectarage: this.maize_allocated_hectarage,
        maize_assigned_numbers_of_farmers: this.maize_assigned_numbers_of_farmers,
        cassava_allocated_hectarage: this.cassava_allocated_hectarage,
        cassava_assigned_numbers_of_farmers:this.cassava_assigned_numbers_of_farmers
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        commodity_rf: this.commodity_rf,
        farm_location: this.farm_location,
        farm_size_ha: this.farm_size_ha,
        farm_cluster:this.farm_cluster,
        phone:this.farmerData.phone,
        farmer_id:this.farmerData.farmer_id,
        source:this.userData.role,
        user_id:this.userData.user_id,
        record_count:this.record_count
      }

      return data
    },
    postData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()
      
      //validate
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        const formUrl1 = httpServ.getNAGSAPUrl('/admin/saveStateQuota')
        const formUrl2 = httpServ.getNAGSAPUrl('/admin/updateStateQuota')
        const url = this.mode === 'new' ? formUrl1 : formUrl2
        const resp = httpServ.postFetch(url,postData)

        console.log(this.mode)
        /* const resp = httpServ.postFetchWithToken(
          url,
          postData,
          this.memberData.jwt
        )*/

        resp.then((result) => {
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    postData2: function () {
      const postData = {
        commodity_rf: this.selected_commodity_rf,
        phone: this.farmerData.phone
      }
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/farm/commodity_status')
      const resp = httpServ.postFetch(formUrl,postData)

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close2()
          this.getTableData()
          this.formBtnLoader = false
        } else if (result.status === 422) {
            
          if (result instanceof SyntaxError) {
            // Unexpected token < in JSON
            //console.log('There was a SyntaxError', result)//checker
            this.snackbartext = result.message
            this.snackbar = true
          } else {
            //console.log('There was an error', result) //Checker
            this.snackbartext = result.message
            this.snackbar = true
          }
          this.formBtnLoader = false
        } else {
          this.snackbartext = result.message 
          this.snackbar = true
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      this.commodity_rf = item.commodity_rf
      this.farm_location = item.farm_location
      this.farm_size_ha =  item.farm_size_ha
      this.farm_cluster = item.farm_cluster

      /*this.transaction_date = this.$moment(
        item.transaction_date,
        'DD MMM YYYY'
      ).format('YYYY-MM-DD')*/
      this.commodity_disabled = true
      this.dialog = true
      this.mode = 'update'
    },   
    editPrimaryStatus(item) {
      //console.log(item)
      this.selected_commodity = item.commodity
      this.selected_commodity_rf = item.commodity_rf
      this.dialog2 = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
      //this.editedItem = Object.assign({}, this.defaultItem)
        this.state = ''
        this.cropping_year = ''
        this.cropping_season = ''
        this.lga = ''
        this.intervention_rf = ''
        this.cluster_irrigation_site = ''
        this.input_collection_centers = ''
        this.rice_allocated_hectarage = ''
        this.rice_assigned_numbers_of_farmers = ''
        this.maize_allocated_hectarage = ''
        this.maize_assigned_numbers_of_farmers = ''
        this.cassava_allocated_hectarage = ''
        this.cassava_assigned_numbers_of_farmers = ''
        this.editedIndex = -1
        this.verifyBtnLoader = false
        this.formDisabled = true
        this.formBtnLoader = false
        this.commodity_disabled = false
        this.mode = 'new'
      })
    },
    close2() {
      this.selected_commodity = ''
      this.selected_commodity_rf = ''
      this.dialog2 = false
    }
  }
}
</script>
  
  <style scoped>
  </style>
